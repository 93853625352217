import { createStore } from 'vuex'
import { isLoggedIn, logOut, logIn, UserMenu } from '../shared/utils/auth'
import axios from 'axios';
const store = createStore({
    state(){
        return{
          isLoggedIn: false,
          base_url:'https://tmsapipost.areteonline.in/',
          category:[],
          subcategory:[],
          items:[],
          offerlist:[],
          MostPopular:[],
        }
    },
    mutations:{
      setLoggedIn(state, payload) {
        console.log('-----***-----',payload);
        state.isLoggedIn = payload
      },
        setUser(state, payload) {
            state.user = payload
          },
          getprojectsROW(state, payload) {
            state.category = payload
          },
          getsubCategory(state,payload){
            state.subcategory = payload
          },
          getItem(state,payload){
            state.items = payload
          },
          getOfferListROW(state,payload)
          {
            state.offerlist = payload
          },
          getMostPopularListROW(state,payload){
            state.MostPopular = payload
          }
       

    },
    actions:{

      menuAction({commit}){
        let role=localStorage.getItem('Role');

        if(role=='manager'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='User'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Administrator'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Director'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }

      },
      loadStoredState(context) {
        context.commit('setLoggedIn', isLoggedIn())
        // context.commit('setUsername')
      },
          login({ dispatch },payload) {
            // simulate login
            dispatch('loadUser',payload)
          },
          logout({ commit }) {
            localStorage.setItem("userID",null);
            localStorage.setItem("Role",'');
            commit('setUser', {})
            commit('setLoggedIn', false)
            logOut()
          },
          async loadUser({ commit, dispatch },payload) {
            console.log('------loaduser----');
            if (!isLoggedIn()) {
              try {
                  var optionAxios = {
                  headers: {
                              'Content-Type': 'multipart/form-data',
                          }                         }
              // Make a request for a user with a given ID
              await  axios.post('https://saaapi.areteonline.in/index.php/Add-customer',payload,optionAxios)
                      .then(function (res) {
                            
                            commit('setUser', res.data)

                            localStorage.setItem("Username", payload.name);
                            localStorage.setItem("mobile",payload.mobile);
                            localStorage.setItem("table",payload.table,18000);
                            
                            document.cookie = 'Usernum' + "=" + payload.mobile; 
                            document.cookie= 'Usertype' + "="+payload.name;
                            
                            dispatch('loadStoredState')
                            UserMenu()
                            // commit('setUserIn', true)
                            logIn()

                                return true;
                            
                          
                      })
                      .catch(function (error) {
                          // handle error
                          console.log(error);
                      })
                      .then(function () {
                          // always executed
                          console.log('---------')
                      });
              
              } catch (error) {
                dispatch('logout')
              }
            }
          },
    
     
        
     
      async category({commit}){
        const response = await axios.get(`https://saaapi.areteonline.in/index.php/Api-category`)
        const data = response.data;
        commit('getprojectsROW',data);
      },
      async Subcategory({commit},payload){
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }       }
          await  axios.post(`https://saaapi.areteonline.in/index.php/Api-Subcategory`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getsubCategory',res.data);
                //  console.log(document.getElementsByClassName(".modal-backdrop").remove('show'));
                 
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async ItemsDisplay({commit},payload){
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }       }
          await  axios.post(`https://saaapi.areteonline.in/index.php/Search-Api-Item`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getItem',res.data);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async SearchItemsDisplay({commit},payload){
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }       }
          await  axios.post(`https://saaapi.areteonline.in/index.php/Search-Api-Item-details`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getItem',res.data);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async OfferList({commit})
      {
        const response = await axios.get(`https://saaapi.areteonline.in/index.php/Add-Offer-List`)
        const data = response.data;
        commit('getOfferListROW',data);
      },
      async MostPopular({commit},payload)
      {
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }       }
          await  axios.post(`https://saaapi.areteonline.in/index.php/API-Most-Popular`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getMostPopularListROW',res.data);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

        // const response = await axios.get(`https://saaapi.areteonline.in/index.php/API-Most-Popular`)
        // const data = response.data;
        // commit('getMostPopularListROW',data);
      }

    },
    getters:{
      fetchCategory(state){
            return state.category;
        },
      fetchsubCategory(state){
          return state.subcategory;
        },
      fetchItems(state){
        return state.items;
        },
        fetchOffer(state){
          return state.offerlist;
        },
        fetchMostPopular(state){
          return state.MostPopular;
        }
        
    }

});

export default store;