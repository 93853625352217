<template>
  <div>
    <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <link rel="icon" type="image/png" href="assets/img/logo_web.png" />
    <link href="/assets/img/fav.png" rel="icon">
    <link href="/assets/img/logo_web.png" rel="apple-touch-icon">

    <title>Sam's Alive Again</title>

  </head>
  <body class="fixed-bottom-bar body bgimage">
    <header class="section-header">
      <section class="header-main shadow-sm bg-white">
        <div class="container">
          <div class="row align-items-center">
            <div class="p-2">
                <center>
                    <a href="/" class="brand-wrap mb-0">
                        <img alt="#" class="img-fluid" src="assets/img/logo.png" />
                    </a>
                </center>
            </div>
          </div>
        </div>
      </section>
    </header>
    <div class="osahan-home-page">
       
      <router-view></router-view>
    </div>
    <div class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center" >
      <div class="row">
        <div class="col selected">
          <a href="tel:9925020237" class="text-danger small fw-bold text-decoration-none">
            <p class="h4 m-0"><i class="feather-phone text-danger"></i></p>
            Call
          </a>
         <!-- <a href="tel:8980044804" style="color:#cfa54c;">
              <img src="assets/img/call.jpg" width="50" height="50" alt="" srcset="">
        </a> -->
        </div>
        <div class="col selected">
          <a href="https://www.facebook.com/samsaliveagain" class="text-danger small fw-bold text-decoration-none">
            <p class="h4 m-0"><i class="feather-facebook text-danger"></i></p>
            Facebook
          </a>

        </div>
        <div class="col bg-white rounded-circle mt-n4 px-3 py-2 ">
          <div class="bg-success rounded-circle mt-n0 shadow bgimage1" @click="homepage()">
            <img alt="#" class="img-fluid" src="assets/img/logo.png" />
          </div>
        </div>
        <div class="col">
          <a href="https://www.instagram.com/samsaliveagain_/" class="text-danger small fw-bold text-decoration-none">
            <p class="h4 m-0"><i class="feather-instagram text-danger"></i></p>
            Instagram
          </a>

        </div>
        <div class="col">
          <div v-if="this.$store.state.isLoggedIn">
            <a  class="text-danger small fw-bold text-decoration-none" @click="previous()">
            <p class="h4 m-0"><i class="feather-skip-back text-danger"></i></p>
            Back
          </a>
          </div>
        </div>
      </div>
    </div>
    
  </body>
    </div>
</template>
<script>
import { mapGetters } from "vuex"

  export default {
      name: 'App',
      inheritAttrs: false,
      data() {
        return {
          Search_Item:'',
          loader:false,
          checklogin:this.$store.state.isLoggedIn,
          sessioncheckdata:'',
          usersdata:'',
          role:'',
          username:localStorage.getItem('Username'),
          checkVEG:localStorage.getItem('Homepage'),
        }
      },
  computed: {
        ...mapGetters(['fetchOffer'])
      },
      methods:{
        homepage()
        {
          this.$router.push({ name: 'Home'})
        },
       
        previous()
        {
          this.$router.go(-1);

        }
      }

    }

</script>
<style>

.bgimage{
  background-image: url('./assets/bg.png');
 /* opacity:0.5; */
  /* position:absolute; */
  top:0;
  bottom:0;
  right:0;
  left:0;
  width:auto;
  height:auto;
  z-index:-3;
  background-size : cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bgimage1{
  background-image: url('./assets/bg.png');
  background-size : cover;
  /* height : 100vh; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.selectbox{
  color: #555;
    display: flex;
    padding: 2px;
    border: 1px solid currentColor;
    border-radius: 5px;
    /* margin: 0 0 30px; */
    margin: -1px 3px 8px 6px;
}
.modal-backdrop{
  position: unset !important;
}
.text-danger{
  color: #7ab500 !important;
  font-weight: bold;
}
.text-white {
    color: #7bb602 !important;
}
.bg-white{
  color: #7bb602 !important;
}


.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}


form {
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid currentColor;
  border-radius: 5px;
  /* margin: 0 0 30px; */
  margin: -1px 3px 8px 6px;
}

input[type="search"] {
  border: none;
  /* background: transparent; */
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
}

button[type="submit"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: white  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

button[type="submit"]:hover {
  opacity: 1;
}

button[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}

form.nosubmit {
 border: none;
 padding: 0;
}

input.nosubmit {
  border: 1px solid #555;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: white  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16'  height='16' class='bi bi-search text-white' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.fixed-bottom-bar {
    padding-bottom:  100%;
}

</style>