<template>
         <div id="loading-wrapper" v-if="loader">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
    <div class="container">
      <br>
      <h5 class="m-0 text-white titlewhite text-center">{{id}}</h5>
      <!-- <div class="pt-4 pb-2 title d-flex align-items-center">
      <h5 class="m-0 text-white">{{id}}</h5> -->
      <!-- </div> --> 
      <p class="text-white mt-1 text-center"></p>
      <div class="trending-slider row">

        <div class="osahan-slider-item mb-2 col-sm-3 member" v-for="subct in fetchsubCategory" :key="subct">
          <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm" @click="Itemcategory(subct.subcateg,subct.Id)" style="color: #cfa54c !important;">
            <div class="list-card-image">
              <div
                class="favourite-heart text-danger position-absolute rounded-circle"
              >
                
              </div>
              <div class="member-plan position-absolute">
                <span class="badge text-bg-dark"></span>
              </div>
              <a>
                <img alt="#" :src="'https://saaapi.areteonline.in/assets/upload/img/subcateg/'+subct.img" 
                  class="img-fluid item-img w-100" style="height: 195px;" />
              </a>
            </div>
            <div class=" position-relative">
              <div class="list-card-body">
                <h6 class="mb-1 text-center">
                  <a  class="text-white text-center"
                    >{{ subct.subcateg }}
                  </a>
                </h6>
                <!-- <p class="text-gray mb-3">--------</p> -->
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <br>

    </div>

</template>
<script>
import { mapGetters } from "vuex"

  // import axios from 'axios'
export default {
        name: 'HelloWorld',
        beforeMount() {
            this.getcateItem();
        },
        computed: {
        ...mapGetters(['fetchsubCategory'])
      },
        data() {
            return {
                id: this.$route.query.q1, //this is the id from the browser
                id2: this.$route.query.q2, //this is the id from the browser
                categoriesitem:[],
                loader:false,
            }
        },
        methods: {
            backbtn(){
                this.$router.push('/Home');
            },        
          async  getcateItem() {
                this.loader=true;
                let itemid = this.id2;
                // let it=itemid.replace(/[^a-zA-Z0-9,;\-.!? ]/g, '');
                await this.$store.dispatch('Subcategory',{id:itemid});
                this.loader=false;
            },
            Itemcategory(id,subCatID)
            {
                this.$router.push({ name: 'items',params: {id:id,subcat:subCatID}, query: {q1: id,q2:subCatID}});
            }

        }
    }
</script>
<style>
.modal-backdrop{
  position: unset !important;
}
.modal-backdrop
{
  display: none;
}
.text-bg-dark {
    background-color: #ba7b36 !important;
}
.member {
    position: relative;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    padding: 5px;
    border-radius: 5px;
    transition: 0.5s;
    border: solid;
    border-color: #6cac02;
    
}
.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 90px;
    height: 30px;
    margin: 0 auto;
    font-size: 10px;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #cfa54c;
}
.team .member p {
    margin: 5px 0 0 0;
    font-size: 14px;
}

@media all and (min-width:960px) and (max-width: 1024px) {
  /* put your css styles in here */
  .carouslide{
    widows: 328px !important;
  }
}

@media all and (min-width:801px) and (max-width: 959px) {
  /* put your css styles in here */
}

@media all and (min-width:769px) and (max-width: 800px) {
  /* put your css styles in here */
}

@media all and (min-width:569px) and (max-width: 768px) {
  /* put your css styles in here */
 
}

@media all and (min-width:481px) and (max-width: 568px) {
  /* put your css styles in here */
 
}

@media all and (min-width:321px) and (max-width: 480px) {
  /* put your css styles in here */
  /* .osahan-slider-item{
    width: 184px;
    margin-right: 4px;
  } */
}

@media (min-width:414px) and (max-width: 896px) {
  /* put your css styles in here */
  /* .osahan-slider-item{
    width: 200px !important;
    margin-right: 7px;
  } */
}

@media (min-width:412px) and (max-width: 915px) {
  /* put your css styles in here */
  /* .osahan-slider-item{
    width: 200px;
    margin-right: 7px;
  } */
}

@media (min-width:360px) and (max-width: 740px) {
  /* put your css styles in here */
  /* .osahan-slider-item{
    width: 180px;
    margin-right: 7px;
    margin-left: 7px;
  } */
}


@media all and (min-width:0px) and (max-width: 320px) {
  /* put your css styles in here */
  /* .subcategoryDIV{
    width: 115px;
    height:155px;
  } */
}

</style>