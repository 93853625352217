<template>
    <div id="loading-wrapper" v-if="loader">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
  </div>
  
  <div class=" p-3 d-none">
            <div class="title d-flex align-items-center" style="flex-direction: column;">
             
              <div>
                  <img alt="#" class="img-fluid" src="assets/img/logo.png" style="height: 100px;" />
              </div>
            </div>
          </div>
      <section >
        <div class="container-fluid" style="margin-top: 10%;">
    
            <div class="container">
            <div class="forms">
                <div class="form-content">
                    <div class="login-form">
    
                        <!-- <img src="assets/img/logo.png" class="img-fluid" alt="" style="height:100px;width:150px"> -->
                        <div class="title mb-2 text-center" style="align-items:center">Sam's Alive Again</div>
                              <form  @submit.prevent="checkLogin" style="padding: 10px !important;">
    
                              <div class="input-boxes">
                                <div class="input-box">
                                    <i class="fas fa-user"></i>
                                    
                                    <input class="form-control text-box single-line" id="name" v-model="name" placeholder="Enter your name" type="text" required>
                                </div>
                                <div class="input-box">
                                    <i class="fa fa-phone-square" aria-hidden="true" style="transform: rotateY(180deg);"></i>
                                    <input class="form-control text-box single-line"   pattern="[^[6-9]\d{9}$]" maxLength="10" required id="mobile" v-model="mobile" placeholder="Enter your Mobile No" type="number">
                                </div>
                                <div class="button input-box">
                                    <input type="submit" value="Submit">
                                </div>
                                
                            </div>
                          </form>
                    </div>
                </div>
            </div>
        </div>
      </div>
            
          
    <div class="triangle_vert"></div>
    
       
      </section>
    </template>
    
    <script>
    
    export default {
        name:"TMSLogin",
        data() {
                return {
                    // for new tasks
                    id: this.$route.query.tableno?this.$route.query.tableno:'0', //this is the id from the browser
                    loginCheck: false,
                    email: '',
                    password:'',
                    mobile:'',
                    name:'',
                    query: localStorage.setItem("table", JSON.stringify(this.$route.query),18000),
                    loader:false,
    
                };
            },
            computed: {
          },
          mounted() {
          this.mountedFun();
          
          },  
      methods: {
        mountedFun(){
          this.id=this.$route.query.tableno?this.$route.query.tableno:'0';
          this.$router.replace('/')
          let check= localStorage.getItem("isLoggedIn");
               setTimeout(() => {
                if(check==='true' || document.cookie){
                  localStorage.setItem("isLoggedIn", true);
                  localStorage.setItem("checknonveg",0);
                  localStorage.setItem("Homepage",'all');
                  localStorage.setItem("HomeNonveg",'All');
                  this.$router.push('/Home');
                               
                }else
                {
                  this.$router.push('/');
                }
               }, 1000);
              
        },
        
       async checkLogin()
        { 
          this.loader=true;
         
            let payload={
            name:this.name,
            mobile:this.mobile,
            table:this.id
          }
          if (/^[6,7,8,9][0-9]{0,9}$/.test(this.mobile)){
    
            await this.$store.dispatch('login',payload);
    
            setTimeout(() => {
                let check= localStorage.getItem("isLoggedIn");
                if(check==='true' || check==true || document.cookie){
                  localStorage.setItem("isLoggedIn", true);
                  localStorage.setItem("checknonveg",0);
                  localStorage.setItem("Homepage",'all');
                  localStorage.setItem("HomeNonveg",'All');
                  this.$store.dispatch('loadStoredState')
                  this.loader=false;
                  this.$router.push('/Home');
    
                }else
                {
                  this.loader=false;
                  console.log('error',localStorage.getItem("isLoggedIn"));
                  this.mobile='';
                  this.mountedFun();
                  this.$router.push('/');
                  this.$swal({
                      icon: 'error',
                       text: "Mobile not Valid",
                       target: '#custom-target',
                       customClass: {
                         container: 'position-absolute'
                       },
                       toast: true,
                       position: 'center',
                       timer: 1500,
                       showConfirmButton: false,
  
                   });
    
                }
                
              }, 3000);
              
          }
          else
          {
            this.loader=false;
            this.$swal({
                        icon: 'error',
                        text: 'Mobile Number Not valid.',
                        target: '#custom-target',
                        customClass: {
                          container: 'position-absolute'
                        },
                        toast: true,
                        position: 'center',
                        timer: 1500,
                        showConfirmButton: false,
  
                    });
    
                    this.mobile='';
                    this.$router.push('/');
    
          }  
        }
    }
    
    };
    </script>
    <style scoped>
    
    body {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }
    body {
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 1.42857143;
        color: #333;
        background-color: #fff;
        background-image: url('../assets/bg.png');
    }
    .forms .form-content .title {
        position: relative;
        font-size: 24px;
        font-weight: 500;
        color: #333;
    }
    #footer {
        font-size: 22px;
        background: #9c4a29;
    }
    .footer {
        position: fixed;
        /* left: 0; */
        bottom: 0;
        width: 100%;
        height: 50px;
        /* text-align: center; */
    }
    section#accueil {
      background: #ecf6f8;
      position: relative;
      width: 100%;
      height: 100vh;
      overflow: hidden;
    }
    
    
    .container {
        position: relative;
        max-width: 850px;
        width: 100%;
        background: #fff;
        padding: 40px 30px;
        box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
        perspective: 2700px;
    }
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    
    .container{
      position: relative;
      max-width: 850px;
      width: 100%;
      background: #fff;
      padding: 40px 30px;
      box-shadow: 0 5px 10px rgba(0,0,0,0.2);
      perspective: 2700px;
    }
    .container .cover{
      posght: 100%;
      width: 50%;
      z-index: 98;
      transition: all 1s ease;
      transform-origin: left;
      transform-style: preserve-3d;
    }
    .container #flip:checked ~ .cover{
      transform: rotateY(-180deg);
    }
     .container .cover .front,
     .container .cover .back{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    .cover .back{
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }
    .container .cover::before,
    .container .cover::after{
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: #37517e;
      opacity: 0.5;
      z-index: 12;
    }
    .container .cover::after{
      opacity: 0.3;
      transform: rotateY(180deg);
      backface-visibility: hidden;
    }
    .container .cover img{
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 10;
    }
    .container .cover .text{
      position: absolute;
      z-index: 130;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .container .forms{
      height: 100%;
      width: 100%;
      background: #fff;
    }
    .container .form-content{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .form-content .login-form,
    .form-content .signup-form {
        /*width: calc(100% / 2 - 25px);*/
        width: calc(100%);
    }
    
    .forms .signup-form  .title:before{
      width: 20px;
    }
    .forms .form-content .input-boxes{
      margin-top: 30px;
    }
    .forms .form-content .input-box{
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      margin: 10px 0;
      position: relative;
    }
    .form-content .input-box input{
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      padding: 0 30px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 2px solid rgba(0,0,0,0.2);
      transition: all 0.3s ease;
    }
    .form-content .input-box input:focus,
    .form-content .input-box input:valid{
      border-color: #0000f3;
    }
    .form-content .input-box i{
      position: absolute;
      color: #37517e;
      font-size: 17px;
    }
    .forms .form-content .text{
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
    .forms .form-content .text a{
      text-decoration: none;
    }
    .forms .form-content .text a:hover{
      text-decoration: underline;
    }
    .forms .form-content .button{
      color: #fff;
      margin-top: 40px;
    }
    .forms .form-content .button input{
      color: #fff;
      background: #0000f3;
      border-radius: 6px;
      padding: 0;
      cursor: pointer;
      transition: all 0.4s ease;
    }
    .forms .form-content .button input:hover{
      background: #0000f3;
    }
    .forms .form-content label{
      color: #0000f3;
      cursor: pointer;
    }
    .forms .form-content label:hover{
      text-decoration: underline;
    }
    .forms .form-content .login-text,
    .forms .form-content .sign-up-text{
      text-align: center;
      margin-top: 25px;
    }
    
    
  #loading-wrapper {
    width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 999999;
      position: fixed;
      background: #000000ab;
  }
  
  #loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999;
    width: 100px;
    height: 30px;
    margin: -7px 0 0 -45px;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 20px;
  }
  
  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid #F00;
  }
  
  #loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid #0F0;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }
  
  #loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid #00F;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }
  
  #loading-content {
    border: 3px solid transparent;
    border-top-color: #4D658D;
    border-bottom-color: #4D658D;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }
  
  #loading-content:before {
    border: 3px solid transparent;
    border-top-color: #D4CC6A;
    border-bottom-color: #D4CC6A;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
  }
  
  #loading-content:after {
    border: 3px solid transparent;
    border-top-color: #84417C;
    border-bottom-color: #84417C;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
  }
  
  @-webkit-keyframes loaders {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  #content-wrapper {
    color: #FFF;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
  }
  
  #header
  {
    width: 800px;
    margin: 0 auto;
    text-align: center;
    height: 100px;
    background-color: #666;
  }
  
  #content
  {
    width: 800px;
    height: 1000px;
    margin: 0 auto;
    text-align: center;
    background-color: #888;
  }
  
    </style>
    