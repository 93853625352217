import { createWebHashHistory, createRouter } from "vue-router";
// import TmsLogin from "../views/TmsLogin.vue";
import Home from "../views/HomeView.vue";
import categories from "../views/categories_multi.vue";
import items from "../views/items_details.vue";
import searchitem from "../views/search_details.vue";

import Login from "../views/Login";
import NotFound from "../views/NotFound.vue";
// ending manager


// import NotFound from "../views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
       // beforeEnter (to, from, next) {
            // if (localStorage.getItem("isLoggedIn")==='true') {
            //   next()
            // } else {
            //   next('/')
            // }
         // },
        meta: {
            reload: true,
          },
      },
      {
      path: "/Home",
      name: "Home",
      component: Home,
      beforeEnter (to, from, next) {
        if (localStorage.getItem("isLoggedIn")==='true') {
          next()
        } else {
          next('/')
        }
     },
      meta: {
          reload: true,
        },
    },
    {
        path: '/categories',
        name: 'categories',
        component: categories,
        beforeEnter (to, from, next) {
            if (localStorage.getItem("isLoggedIn")==='true') {
              next()
            } else {
              next('/')
            }
         },
        props: route => ( { query: route.query.q } )

    },
    {
        path: '/items',
        name: 'items',
        component: items,
        beforeEnter (to, from, next) {
            if (localStorage.getItem("isLoggedIn")==='true') {
              next()
            } else {
              next('/')
            }
         },
        props: route => ( { query: route.query.q } )

    },
    {
      path: '/searchitem',
      name: 'searchitem',
      component: searchitem,
      beforeEnter (to, from, next) {
          if (localStorage.getItem("isLoggedIn")==='true') {
            next()
          } else {
            next('/')
          }
       },
      props: route => ( { query: route.query.q } )
  },

    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

    
];

const router = createRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    history: createWebHashHistory(),
    routes,
});

export default router;