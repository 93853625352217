
<template>
  <div id="loading-wrapper" v-if="loader">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
  <div class="">
    <div class="p-1 d-none">
          <carousel :items-to-show="2" class="" :autoplay="3000" :wrapAround="true">
          <slide v-for="offr in fetchOffer" :key="offr">
            
                <div class="cat-item px-1 py-3 ">
                  <a class="d-block text-center shadow-sm">
                    <img alt="#" :src="'https://saaapi.areteonline.in/assets/upload/img/cat/'+offr.image"
                  class="img-fluid rounded"
                />
                  </a>
                </div>
          </slide>

            <template #addons>
              <navigation />
              <!-- <pagination /> -->
            </template>

          </carousel>

        <div class="">
          <div class="col-sm-12 d-flex" style="flex-wrap: nowrap;flex-direction: row-reverse;justify-content: space-between;">

              <form>
                <select v-model="checkVEG" name="" id="" class="form-control" v-on:change="checkbox()" style="padding: 0.575rem .75rem !important;">
                  <option value="all" selected>All</option>
                  <option value="0">Veg</option>
                  <option value="1">Non-Veg</option>
                </select>
              </form>

            <form>
              <input type="search" v-model="Search_Item" placeholder="Search...">
              <button type="submit" v-on:click="SearchItem()">Search</button>
            </form>
          </div>
        </div>
        
      </div>
      
      <div class="container">
        <h5 class="m-0 text-white titlewhite text-center">Menu</h5>
       <p class="text-white mt-1 text-center">All the images are illustration purpose only</p>
      <div class="trending-slider row col-sm-12" style="display: inline-flex;justify-content: center;">
        <div v-for="slide in fetchCategory" :key="slide" class="osahan-slider-item col-sm-3 mb-3" >
          <div
            class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm bordercls" @click="subcategory(slide.Name,slide.Id)">
            <div class="list-card-image">
             
              <div class="member-plan position-absolute">
                <span class="badge text-bg-dark">Menu</span>
              </div>
              <a>
                <img
                  alt="#"
                  :src="'https://saaapi.areteonline.in/assets/upload/img/cat/'+slide.img"
                  class="img-fluid item-img w-100"
                />
              </a>
            </div>
            <div class=" position-relative text-center">
              <div class="list-card-body">
                <h6 class="mb-1">
                  <a  class="text-white"
                    >{{slide.Name}}
                  </a>
                </h6>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
     
    </div>
 
    
    <div class="container">
      <h5 class="m-2 mt-2 text-white text-center titlewhite">Most Popular</h5>
      <div class="py-3 title d-flex align-items-center">
      </div>
      <div class="most_popular">
        <div class="row">
          <div class="col-md-3 pb-3" v-for="most in fetchMostPopular" :key="most">
            <div v-if="most.vegnon!=veg"   class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm bordercls"
            >
              <div class="list-card-image">
                <div class="star position-absolute">
                </div>
                <div
                  class="favourite-heart text-danger position-absolute rounded-circle"
                >
                
                </div>
                <div class="member-plan position-absolute">
                  <span class="badge">
                    <p style="color: black; font-size:12px;text-align: center;" v-if="most.vegnon=='0'" ><b><img width="20" height="20" src="assets/img/veg.jpg" alt="" srcset=""></b></p>
                  <p style="color: black; font-size:12px;text-align: center;" v-if="most.vegnon=='1'" ><b><img width="20" height="20" src="assets/img/nonveg.jpg" alt="" srcset=""></b></p>
                  </span>
                </div>
                <a>
                  <img alt="#"  :src="'https://saaapi.areteonline.in/assets/upload/img/cat/'+most.image"
                    class="img-fluid item-img w-100"
                  />
                </a>
              </div>
              <div class=" position-relative">
                <div class="list-card-body">
                  <h6 class="mb-1">
                    <a class="text-black"
                      >{{ most.title }}
                    </a>
                  </h6>
                  <p class="text-white mb-1 small">{{ most.description }}</p>
                  <p class="text-white mb-1 rating"></p>
                  <ul class="rating-stars list-unstyled">
                    <li>₹ {{ most.price }}</li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { mapGetters } from "vuex"

export default {
  data() {
          return {
            checkVEG:localStorage.getItem('Homepage'),
            veg:localStorage.getItem('HomeNonveg'),
            Checkerveg:localStorage.getItem('varibleNonveg'),
            loader:false,
            slidimg:[{"imgURL":'assets/img/menuimg/bever.jpg',"title":"Beverage's"},
            {"imgURL":'assets/img/menuimg/Liquor.jpg',"title":"Liquor"},
            {"imgURL":"assets/img/menuimg/food.jpg","title":"Food"},
            {"imgURL":"assets/img/menuimg/comingsoon.png","title":"Happy Hours"}],            

            slideoffer:[{"imgURL":'assets/img/pro1.jpg'},
            {"imgURL":'assets/img/pro2.jpg'},
            {"imgURL":"assets/img/pro3.jpg"},
            {"imgURL":"assets/img/pro4.jpg"}]
          }
      },
      components: {
    Carousel,
    Slide,
    Navigation,
  },
      computed: {
        ...mapGetters(['fetchCategory','fetchOffer','fetchMostPopular'])
      },
      mounted() {
        this.fetchcategories();
        this.fetchoffers();
      },
      methods:{
        SearchItem()
        {
            if(this.Search_Item)
            {
              this.$router.push({ name: 'searchitem',params: {id:this.Search_Item}, query: {q1: this.Search_Item}})

              //this.$router.push({ name: 'searchitem',params: {id:this.Search_Item}, query: {q1: this.Search_Item}})
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);

            }else
            {
              this.$swal({
                    icon: 'error',
                     text: "Please Enter Search Item Details",
                     target: '#custom-target',
                     customClass: {
                       container: 'position-absolute'
                     },
                     toast: true,
                     position: 'center',
                     timer: 1500,
                     showConfirmButton: false,

                 });
                 console.log('--------------**********');
                 window.location.reload();
            }

        },
        checkbox()
        {
          
          if(this.checkVEG=='all')
          {
            localStorage.setItem("varibleNonveg",'all');
            localStorage.setItem("HomeNonveg",'all');
            localStorage.setItem("Homepage",'all');

            this.checkVEG='all';
          }
          else if(this.checkVEG=='0')
          {
            localStorage.setItem("varibleNonveg",'Non-Veg');
            localStorage.setItem("HomeNonveg",'1');
            localStorage.setItem("Homepage",'0');
            this.checkVEG='0';

          }
          else
          {
            localStorage.setItem("varibleNonveg",'Veg');
            localStorage.setItem("HomeNonveg",'0');
            localStorage.setItem("Homepage",'1');
            this.checkVEG='1';
          }

          // localStorage.setItem("checknonveg",check);
          window.location.reload();
        },
        async fetchoffers()
        {
          this.loader=true;
          await this.$store.dispatch('OfferList')
          this.loader=false;
          // localStorage.setItem("Homepage",'all');
        },
        async fetchcategories(){
          this.loader=true;
          localStorage.setItem("varibleNonveg",'all');
           await this.$store.dispatch('category')
         //  await this.$store.dispatch('OfferList')
         let payload={
          checker:this.veg
         };
           await this.$store.dispatch('MostPopular',payload)
            this.loader=false;
            
        
          //fetchDepart
        },
        subcategory(id,catID)
        {
          this.$router.push({ name: 'categories',params: {id:id,catID:catID}, query: {q1: id,q2:catID}})
        }

      }
}
</script>

<style>
.titlewhite::before{
  content: "";
    position: relative;
    display: block;
    width: 120px;
    height: 3px;
    background: #d40404;
    top: 29px;
    left: calc(50% - 60px);

}

.titlewhite::after{
  content: "";
    position: relative;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: -2px;
    left: calc(50% - 20px);
}

.bordercls{
  padding: 5px;
    border-radius: 5px;
    transition: 0.5s;
    border: solid;
    border-color: #7ab500;
    background: #7ab500;
}
.bg-white{
  background-color: #0f1844;
  color: white;
}
.rounded-circle {
  border-radius: 4%!important;
}
.bg-danger {
    background: linear-gradient(-66deg,#0000f3 49%,#f69a00 100%) !important;;
}


#loading-wrapper {
  width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999;
    position: fixed;
    background: #000000ab;
}

#loading-text {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #999;
  width: 100px;
  height: 30px;
  margin: -7px 0 0 -45px;
  text-align: center;
  font-family: 'PT Sans Narrow', sans-serif;
  font-size: 20px;
}

#loading-content {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 170px;
  height: 170px;
  margin: -85px 0 0 -85px;
  border: 3px solid #F00;
}

#loading-content:after {
  content: "";
  position: absolute;
  border: 3px solid #0F0;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

#loading-content:before {
  content: "";
  position: absolute;
  border: 3px solid #00F;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
}

#loading-content {
  border: 3px solid transparent;
  border-top-color: #4D658D;
  border-bottom-color: #4D658D;
  border-radius: 50%;
  -webkit-animation: loader 2s linear infinite;
  -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 2s linear infinite;
}

#loading-content:before {
  border: 3px solid transparent;
  border-top-color: #D4CC6A;
  border-bottom-color: #D4CC6A;
  border-radius: 50%;
  -webkit-animation: loader 3s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
  animation: loader 3s linear infinite;
}

#loading-content:after {
  border: 3px solid transparent;
  border-top-color: #84417C;
  border-bottom-color: #84417C;
  border-radius: 50%;
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
  -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#content-wrapper {
  color: #FFF;
  position: fixed;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
}

#header
{
  width: 800px;
  margin: 0 auto;
  text-align: center;
  height: 100px;
  background-color: #666;
}

#content
{
  width: 800px;
  height: 1000px;
  margin: 0 auto;
  text-align: center;
  background-color: #888;
}


@media all and (min-width:960px) and (max-width: 1024px) {
  /* put your css styles in here */
}

@media all and (min-width:801px) and (max-width: 959px) {
  /* put your css styles in here */
}

@media all and (min-width:769px) and (max-width: 800px) {
  /* put your css styles in here */
}

@media all and (min-width:569px) and (max-width: 768px) {
  /* put your css styles in here */
 
}

@media all and (min-width:481px) and (max-width: 568px) {
  /* put your css styles in here */
 
}

@media all and (min-width:321px) and (max-width: 480px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 160px;
    margin-right: 4px;
  }
}

@media (min-width:414px) and (max-width: 896px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 180px !important;
    margin-right: 7px;
  }
}

@media (min-width:412px) and (max-width: 915px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 180px;
    margin-right: 7px;
  }
}

@media (min-width:360px) and (max-width: 740px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 176px;
    margin-right: 7px;
    margin-left: 7px;

  }
}


@media all and (min-width:0px) and (max-width: 320px) {
  /* put your css styles in here */
  .subcategoryDIV{
    width: 115px;
    height:155px;
  }
}


</style>
