<template>
        <div id="loading-wrapper" v-if="loader">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
  </div>
    <div class="container">
      <!-- <div class="pt-4 pb-2 title d-flex align-items-center">
        <h5 class="m-0 text-white">{{id}}</h5>
      </div> -->
      <br>
      <h5 class="m-0 text-white titlewhite text-center">{{id}}</h5>

      <p class="text-white mt-1 text-center"></p>
      <div class="trending-slider row">

        <div class="osahan-slider-item mb-2 col-sm-3 member" v-for="subct in fetchItems" :key="subct">
          <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm" v-if="subct.t4 !=1" data-toggle="modal" data-target="#modal-default" v-on:click="categoriesFunction(subct)">
            <div class="list-card-image">
              <div class="favourite-heart text-danger position-absolute rounded-circle">
              </div>
              <div class="member-plan position-absolute">
                <span class="badge" v-if="subct.t1==='Veg'"><img width="20" height="20" src="assets/img/veg.jpg" alt="" srcset=""></span>
                <span class="badge " v-if="subct.t1==='Non-Veg'"><img width="20" height="20" src="assets/img/nonveg.jpg" alt="" srcset=""></span>
              </div>
              <a>
                <img alt="#" :src="'https://saaapi.areteonline.in/assets/upload/img/item/'+subct.img" 
                  class="img-fluid item-img w-100" style="height: 195px;" />
              </a>
            </div>
            <div class=" position-relative">
              <div class="list-card-body">
                <h6 class="mb-1 text-center">
                  <a  class="text-white text-center"
                    >{{ subct.item_name }}
                  </a>
                </h6>
                <!-- <p class="text-white mb-3">{{ subct.desc }}</p> -->
                <p class="text-white mb-3 time text-white">
                  <span class="bg-light text-white rounded-sm py-1 px-2" v-if="subct.write!=0" ><i class="feather-note"></i> {{ subct.write }}</span>
                  <span class="float-end text-black-50 text-white">₹ {{ subct.price }} &nbsp;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </div>
    <div class="modal fade "  id="modal-default" @close="showModal = false">
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-uppercase"></h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <h3>{{title}}                
                  <p style="color: black; font-size:12px;text-align: center;" v-if="this.vegnonveg=='Veg'" ><b><img width="20" height="20" src="assets/img/veg.jpg" alt="" srcset=""></b></p>
                  <p style="color: black; font-size:12px;text-align: center;" v-if="this.vegnonveg=='Non-Veg'" ><b><img width="20" height="20" src="assets/img/nonveg.jpg" alt="" srcset=""></b></p>
</h3>
                <center><img :src="image" class="img-fluid text-center" alt="" style="height:200px; width:200px"></center>
                <p style="color: black; font-size:12px;text-align: center;">Price:<b>{{this.price}}</b></p>
                <p style="color: black; font-size:12px;text-align: center;">Description:<b>{{this.description}}</b></p>
                <p style="color: black; font-size:12px;text-align: center;" v-if="this.volume">Volume:<b>{{this.volume}}</b></p>
                
            </div>     
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

</template>
<script>
import { mapGetters } from "vuex"

// import axios from 'axios'
export default {
        name: 'HelloWorld',
        beforeMount() {
            this.getcateItem();
        },
        computed: {
        ...mapGetters(['fetchItems'])
      },
      updated () {
    document.addEventListener("backbutton", this.backButtonPressed);
  },
        data() {
            return {
                id: this.$route.query.q1, //this is the id from the browser
                id2: this.$route.query.q2, //this is the id from the browser
                categoriesitem:[],
                loader:false,
                title:'',
                price:'',
                description:'',
                image:'',
                volume:'',
                vegnonveg:'',
                veg:localStorage.getItem('checknonveg'),
                sessionchecker:localStorage.getItem('varibleNonveg'),
                Homepages:localStorage.getItem('Homepage'),
            }
        },
        methods: {
          backButtonPressed() {
            alert()
    },
            backbtn(){
                this.$router.push('/Home');
            },    
            categoriesFunction(e){
                console.log('*-*--/-/--',e);
                
                this.title=e.item_name;
                this.price=e.price;
                this.description=e.desc;
                this.volume=e.write;
                this.vegnonveg=e.t1;
                this.image=this.getItem(e.img)
            },      
            getItem(imgpath) {
                return 'https://saaapi.areteonline.in/assets/upload/img/item/' + imgpath;
            },
          async  getcateItem() {
              this.loader=true;
                let payload={
                  id:this.id2,
                  checker:this.Homepages
                  };
                  console.log(payload)
                // let it=itemid.replace(/[^a-zA-Z0-9,;\-.!? ]/g, '');
                await this.$store.dispatch('ItemsDisplay',payload);
                this.loader=false;
            },
            Itemcategory(id)
            {
                this.$router.push({ name: 'items',params: {id:id}, query: {q1: id}})
            }

        }
    }
</script>
<style>
.text-bg-dark {
    background-color: #ba7b36 !important;
}
.member {
    position: relative;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    padding: 5px;
    border-radius: 5px;
    transition: 0.5s;
    border: solid;
    border-color: #6cac02;
    
}
.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 90px;
    height: 30px;
    margin: 0 auto;
    font-size: 10px;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #cfa54c;
}
.team .member p {
    margin: 5px 0 0 0;
    font-size: 14px;
}

@media all and (min-width:960px) and (max-width: 1024px) {
  /* put your css styles in here */
}

@media all and (min-width:801px) and (max-width: 959px) {
  /* put your css styles in here */
}

@media all and (min-width:769px) and (max-width: 800px) {
  /* put your css styles in here */
}

@media all and (min-width:569px) and (max-width: 768px) {
  /* put your css styles in here */
 
}

@media all and (min-width:481px) and (max-width: 568px) {
  /* put your css styles in here */
 
}

@media all and (min-width:321px) and (max-width: 480px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 160px;
    margin-right: 4px;
  }
}

@media (min-width:414px) and (max-width: 896px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 180px !important;
    margin-right: 7px;
  }
}

@media (min-width:412px) and (max-width: 915px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 180px;
    margin-right: 7px;
  }
}

@media (min-width:360px) and (max-width: 740px) {
  /* put your css styles in here */
  .osahan-slider-item{
    width: 176px;
    margin-right: 7px;
    margin-left: 7px;

  }
}


@media all and (min-width:0px) and (max-width: 320px) {
  /* put your css styles in here */
  .subcategoryDIV{
    width: 115px;
    height:155px;
  }
}

</style>
