import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import { Bootstrap } from 'bootstrap'
// createApp(App).use(store).use(router).use(Bootstrap).use(VueSweetalert2).mount('#app')
const app = createApp({
    extends: App,
    created() {
      this.$store.dispatch('loadStoredState')
      this.$store.dispatch('loadUser')
    
      //  console.log('............----',this.$store.state.isLoggedIn);
      // let //check= localStorage.getItem("isLoggedIn");
      //   if(check==='true'){
        
      //     this.$router.push('/dashboard');

      //   }
      //   else
      //     {
      //       this.$router.push('/');
      //     }
      // or use `store` directly
      // store.dispatch('loadStoredState')
      // store.dispatch('loadUser')
    },
  })

  app.use(router)
  app.use(store)
  // app.use(Bootstrap)
  app.use(VueSweetalert2)
app.mount('#app')
