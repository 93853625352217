export function isLoggedIn() {
    return localStorage.getItem("isLoggedIn") == 'true' || localStorage.getItem("isLoggedIn")==true;
  }
  
  export function logIn() {
    localStorage.setItem("isLoggedIn", true);
  }
  
  export function logOut() {
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("manager",false);
    localStorage.setItem("User",false);
    localStorage.setItem("Directore",false);
  }

  export function ManagerMenu(){
    localStorage.setItem("manager",true);
  }

  export function UserMenu(){
    localStorage.setItem("User",true);
  }

  export function DirectoreMenu(){
    localStorage.setItem("Directore",true);
  }

  export function isManagerIn() {
    return localStorage.getItem("manager") == 'true';
  }

  export function isUserIn() {
    return localStorage.getItem("User") == 'true';
  }

  export function isDirectoreIn() {
    return localStorage.getItem("Directore") == 'true';
  }